// Device Detection Script
// By Kyle Lamble of Sandstorm Design
// Version 4.0

//
// Currently supports dections of:
//
// Chrome
// Safari
// Opera
// Firefox
// Internet Explorer 5-10 + general IE tag
// NOT IE tag
// Maxthon
// Sea Monkey
// Ipad 
// Iphone
// Android
// Tablet class ( Ipad , Kindle ) 
// Mobile class ( Iphone , Android )
// Retina Displays
// Kindle Fire

//
// New this version:
//
// Added support for Kindle Fire. Applies the "tablet", "kindle", and "not-ie" class
// 


jQuery(document).ready(function(){
    
    if( DetectIpad() === false){
        if( DetectIphone() === false ){
            if( DetectAndroid() === false ){
            	if( DetectKindle() === false ){
	            	DetectBrowser();	
            	}//kindle                            
            }//android                                
        }//iphone        
    }//ipad
            
    DetectOperatingSystem();   
    DetectRetinaDisplay();
    
    if( jQuery('body').hasClass('front') ){
        //IEAlert(); turned off by default, turn on if we do not support lower versions of IE
    }
    
});


// 
// Global Callback Variables
//

var onMobile 					= false;
var onTablet 					= false;
var onChrome 					= false;
var onSafari					= false;
var onOpera  					= false;
var onFirefox 					= false;
var onIE 						= false;
var onIE5 						= false;
var onIE6 						= false;
var onIE7 						= false;
var onIE8 						= false;
var onIE9 						= false;
var onIE10 						= false;
var onMaxthon					= false;
var onSeaMonkey 				= false;
var onIpad 						= false;
var onIphone 					= false;
var onAndroid 					= false;
var onRetinaDisplay 			= false;
var onKindle					= false;


//
// A function that will alert people they are using a version of IE that we do not support.
//

function IEAlert(){
    if(jQuery.browser.msie === true){        
        switch( jQuery.browser.version ){
            case "9.0":
            case "8.0":            
                break;
            case "7.0":
                alert('You are using Microsoft Internet Explorer 7. This site has not been optimized for your browser, please upgrade to a modern browser to ensure the best possible experience.');
                break;
            case "6.0":
                alert('You are using Microsoft Internet Explorer 6. This site has not been optimized for your browser, please upgrade to a modern browser to ensure the best possible experience.');
                break;
            case "5.0":
                alert('You are using Microsoft Internet Explorer 5. This site has not been optimized for your browser, please upgrade to a modern browser to ensure the best possible experience.');
                break;
            default:break;
        }
    }
}

function DetectBrowser(){
    
    var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    var isChrome = !isSafari && testCSS('WebkitTransform');         
    var isMaxthon = /Maxthon[\/\s](\d+\.\d+)/.test(navigator.userAgent);
    var isSeaMonkey = /SeaMonkey[\/\s](\d+\.\d+)/.test(navigator.userAgent);
    
    if(jQuery.browser.mozilla === true){
        jQuery('html').addClass('firefox not-ie');
        onFirefox = true;
    }    
    else if(isSafari === true){
        jQuery('html').addClass('safari not-ie');
        onSafari = true;
        
        var version = navigator.userAgent.substr(navigator.userAgent.lastIndexOf('Version/') + 8, 5);
        
        if(version === "6.0.2"){
            jQuery('html').addClass('v6 not-ie');
        }else{
            jQuery('html').addClass('ltv6 not-ie');
        }
    }
    else if(isMaxthon == true){
        jQuery('html').addClass('maxthon not-ie');
        onMaxthon = true;
    }
    else if(jQuery.browser.opera === true){
        jQuery('html').addClass('opera not-ie');
        onOpera = true;
    }    
    else if(isChrome === true){
        jQuery('html').addClass('chrome not-ie'); 
        onChrome = true;           
    }

    else if(jQuery.browser.msie === true){
    		onIE = true;
            
        switch( jQuery.browser.version ){
            case "10.0":
                jQuery('html').addClass('ie10');
                jQuery('html').addClass('ie');
                onIE10 = true;
                onIE = true;
                break;
            case "9.0":
                jQuery('html').addClass('ie9');
                jQuery('html').addClass('ie');
                onIE9 = true;
                onIE = true;
                break;
            case "8.0":
                jQuery('html').addClass('ie8');
                jQuery('html').addClass('ie');
                onIE8 = true;
                onIE = true;
                break;
            case "7.0":
                jQuery('html').addClass('ie7');
                jQuery('html').addClass('ie');
                onIE7 = true;
                onIE = true;
                break;
            case "6.0":
                jQuery('html').addClass('ie6');
                jQuery('html').addClass('ie');
                onIE6 = true;
                onIE = true;
                break;
            case "5.0":
                jQuery('html').addClass('ie5');
                jQuery('html').addClass('ie');
                onIE5 = true;
                onIE = true;
                break;
        }
    }

    else if(isSeaMonkey === true){
    		onSeaMonkey = true;
        jQuery('html').addClass('seamonkey not-ie');
    }   
}
    
function DetectIphone(){    
    if(navigator.userAgent.match(/iPhone/i)){
        jQuery('html').addClass('iphone mobile not-ie');
        onMobile = true;
        onIphone = true; 
        return true;
    } 
    
    return false;
}    

function DetectIpad(){
    var isiPad = navigator.userAgent.match(/iPad/i) != null;
    
    if(isiPad){
        jQuery('html').addClass('ipad tablet not-ie');
        onIpad = true;
        onTablet = true;
        return true;
    }
    
    return false;
}

function DetectKindle(){
	if (navigator.userAgent.indexOf("Silk") !== -1){
		jQuery('html').addClass('kindle tablet not-ie');
		onTablet = true;
		onKindle = true;
		return true;
	}
	
	return false;
}

function DetectOperatingSystem(){
    if(navigator.platform == "MacIntel"){
        jQuery('html').addClass('mac');
    }
    else if(navigator.platform == "Win32"){
        jQuery('html').addClass('win');
    }
}

function testCSS(prop) {
    return prop in document.documentElement.style;
}

function DetectAndroid(){
    var ua = navigator.userAgent.toLowerCase();
    
    var isAndroid = ua.indexOf("android") > -1;
    
    if(isAndroid) {
        jQuery('html').addClass('android mobile'); 
        onMobile = true;
        onAndroid = true;                
        return true;   
    }
    
    return false;
}

function DetectRetinaDisplay(){
    var retina = window.devicePixelRatio > 1;
    
    if(retina){
        jQuery('html').addClass('retina');
        onRetinaDisplay = true;
    }
}