(function ($, Drupal) {

  // console.log("externals check...");
  // console.log(window.Modernizr);
  // console.log(window.Foundation);


  Drupal.behaviors.STARTER = {
    attach: function(context, settings) {
      Init_Admin_Functionality();
      Init_Contact_Page();
      Init_Tasks();
      Init_Appointments();
      Init_Forms();
      Init_Tables();
      Init_State_Dropdown();
      Init_IEF_Warning();
    }
  };

})(jQuery, Drupal);


function Init_Admin_Functionality(){
	
	Close_Alert_Boxes();
			
	function Close_Alert_Boxes(){
		if( jQuery('.alert-box') ){
			jQuery('.alert-box').find('.close').click(function(e){
				jQuery(this).parent().remove();
			});
		}
	}		
}

function Init_IEF_Warning() {
  if (jQuery('.field-widget-inline-entity-form').length > 0) {
    
    var oktocontinue = false;
    
    jQuery('button#edit-submit, button#edit-submit--2').click(function(e){
      oktocontinue = true;
    });
    
    window.onbeforeunload = function(){
      if (oktocontinue == false) {
        return "Navigating away from this page may cause you to lose data.";
      }
    }
    
  }
}


function Init_Contact_Page(){
	if(jQuery('body').hasClass('page-redhen-contact')){		
		
		var node = jQuery('.entity-redhen-contact');			
	
		function Init_Addresses_Tabs(){
			var collections = node.find('.field-name-field-addresses .field-collection-view');
			
			jQuery(collections[0]).find('.field-name-field-address').addClass('active');			
			jQuery(collections[0]).find('.field-name-field-label').addClass('active');
			
			node.find('.field-name-field-label').click(function(e){				
				
				Clear_Active_Labels();
				
				jQuery(this).addClass('active');
				
				Hide_Active_Addresses();
				
				jQuery(this).siblings('.field-name-field-address').addClass('active');
				
			});
						
			function Clear_Active_Labels(){
				node.find('.field-name-field-label.active').removeClass('active');		
			}
			
			function Hide_Active_Addresses(){
				node.find('.field-name-field-address').removeClass('active');
			}
			
		}				
	
	
		Init_Addresses_Tabs();
		
	}		
}


function Init_Tasks() {
    
  jQuery('.snoozer').click(function(e){
    e.preventDefault();
    jQuery(this).parent().prevAll('.views-field-field-due-date-editable').children('.views-row-edit-edit').slideDown('fast');
    jQuery(this).parent().prevAll('.views-field-field-due-date-editable').children('.views-row-edit-static').slideUp('fast');
  });
  
  jQuery('#editableviews-entity-form-task-block .form-checkbox').change(function(e){
    jQuery('#editableviews-entity-form-task-block').submit();
  });
  
  jQuery('#editableviews-entity-form-completed-tasks-block .form-checkbox').change(function(e){
    jQuery('#editableviews-entity-form-completed-tasks-block').submit();
  });
  
  jQuery('#editableviews-entity-form-contact-related-appointments .form-checkbox').change(function(e){
    jQuery('#editableviews-entity-form-contact-related-appointments').submit();
  });
  
  jQuery('#editableviews-entity-form-appointments-block .form-checkbox').change(function(e){
    jQuery('#editableviews-entity-form-appointments-block').submit();
  });
  
  if (jQuery('a.ical-icon').length > 0) {
    var newhref = jQuery('a.ical-icon').attr('href').replace('%3Ftoken%3D', '?token=');
    jQuery('a.ical-icon').attr('href', newhref);
  }
  
  

}


function Init_Appointments() {
  
  if (jQuery('form#appointment-node-form').length > 0) {
  
    jQuery('#edit-field-date-repeating-und-0-value2-datepicker-popup-0').focus(function(){
      
      if (jQuery('#edit-field-date-repeating-und-0-value2-datepicker-popup-0').val() == '' || jQuery('#edit-field-date-repeating-und-0-value2-datepicker-popup-0').val() < jQuery('#edit-field-date-repeating-und-0-value-datepicker-popup-0').val()) {
        jQuery('#edit-field-date-repeating-und-0-value2-datepicker-popup-0').val(jQuery('#edit-field-date-repeating-und-0-value-datepicker-popup-0').val());
      }
      
      
      
    });
    
    jQuery('#edit-field-date-repeating-und-0-value2-timeEntry-popup-1').focus(function(){
      
      if (jQuery('#edit-field-date-repeating-und-0-value2-timeEntry-popup-1').val() == '') {
        jQuery('#edit-field-date-repeating-und-0-value2-timeEntry-popup-1').val(jQuery('#edit-field-date-repeating-und-0-value-timeEntry-popup-1').val());
      }
      
    });
    
    
  }
  
}


function Init_Forms() {
  jQuery('#edit-field-client-group-target-id-op option:contains("Is empty (NULL)")').text('Show Client Groups and Clients');
  jQuery('#edit-field-client-group-target-id-op option:contains("Is not empty (NOT NULL)")').text('Remove Client Groups');

  jQuery("#edit-submitted-info-social-security-number").mask("999-99-9999",{placeholder:" "});
  jQuery("#edit-submitted-current-address-phone-number").mask("999-999-9999",{placeholder:" "});
  jQuery("#edit-submitted-current-address-zip-code").mask("99999",{placeholder:""});
  
  // Makes vitual keyboard switch to number pad
  jQuery("#edit-submitted-info-social-security-number").attr('pattern','[0-9]*');
  jQuery("#edit-submitted-info-social-security-number").prop('pattern','[0-9]*');
  jQuery("#edit-submitted-current-address-phone-number").attr('pattern','[0-9]*');
  jQuery("#edit-submitted-current-address-phone-number").prop('pattern','[0-9]*');
  jQuery("#edit-submitted-current-address-zip-code").attr('pattern','[0-9]*');
  jQuery("#edit-submitted-current-address-zip-code").prop('pattern','[0-9]*');
  
  jQuery("#webform-client-form-1652").attr('novalidate','');
  jQuery("#webform-client-form-1652").prop('novalidate','');
  
  jQuery('#edit-redhen-note-body-und-0-value').attr('rows','20');
  jQuery('#edit-redhen-note-body-und-0-value').prop('rows','20');
  
  for (i = 0; i < 11; i++) {
    jQuery('#edit-field-phone-numbers-und-' + i + '-field-phone-und-0-value').mask("999-999-9999",{placeholder:" "});
  }
  
  jQuery('#edit-field-fax-und-0-value').mask("999-999-9999",{placeholder:" "});
  jQuery('#edit-field-fein-und-0-value').mask("99-9999999",{placeholder:" "});
  
  jQuery("#edit-field-certified-ssns #field-certified-ssns-values .text-full.form-text").mask("999-99-9999",{placeholder:" "});
  
  // Questionnaire Form Validation
  if (jQuery('form#webform-client-form-1652').length > 0) {
    var oktocontinue = false;
    
    jQuery('.questionnaire-language-wrapper a, button.webform-next, button.webform-previous, button.webform-jump, button.form-submit').click(function(e){
      oktocontinue = true;
    });
    
    window.onbeforeunload = function(){
      if (oktocontinue == false) {
        return "Navigating away from this page may cause you to lose data.";
      }
    }
    
    jQuery(window).load(function() {
      if (jQuery('input[name="details[page_num]"]').val() == 1) {
        // Ask Drupal to translate the string
        var continue_string = Drupal.t('Continue to the survey');
        jQuery('button.form-submit.webform-next').before(continue_string + '<br />');
      }
    });
    
    jQuery('form#webform-client-form-1652').submit(function(e){
      
      
      // Validating birthdate
      if (jQuery('#edit-submitted-info-date-of-birth-month').val() == '' && jQuery('#edit-submitted-info-date-of-birth-day').val() == '' && jQuery('#edit-submitted-info-date-of-birth-year').val() == '') {
        // Birthate is blank
      } else {
        
        if ((jQuery('#edit-submitted-info-date-of-birth-month').val() == '4' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '6' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '9' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '11') && jQuery('#edit-submitted-info-date-of-birth-day').val() > 30) {
          alert('Please enter correct date.');
          return(false);
        }
        if ((jQuery('#edit-submitted-info-date-of-birth-month').val() == '1' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '3' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '5' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '7' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '8' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '10' || jQuery('#edit-submitted-info-date-of-birth-month').val() == '12') && jQuery('#edit-submitted-info-date-of-birth-day').val() > 31) {
          alert('Please enter correct date.');
          return(false);
        }
        if ((jQuery('#edit-submitted-info-date-of-birth-month').val() == '2') && jQuery('#edit-submitted-info-date-of-birth-day').val() > 29) {
          alert('Please enter correct date.');
          return(false);
        }
        if (jQuery('#edit-submitted-info-date-of-birth-month').val() == '' || jQuery('#edit-submitted-info-date-of-birth-day').val() == '' || jQuery('#edit-submitted-info-date-of-birth-year').val() == '') {
          alert('Please enter correct date.');
          return(false);
        }
        
      } // End Validating birthdate
      
      // Validating County
      if (jQuery('#edit-submitted-current-address-county').val().toLowerCase() == 'us' || jQuery('#edit-submitted-current-address-county').val().toLowerCase() == 'usa' || jQuery('#edit-submitted-current-address-county').val().toLowerCase() == 'united states') {
        alert('Please enter your County, not Country. Thank you.');
        return(false);
      }
      
      
    }); // End submit()
    
    // Progress bar width fix
    if (jQuery('.webform-progressbar-page.current').length > 0) {
      var newWidth = jQuery('.webform-progressbar-page.current').position().left / jQuery('.webform-progressbar-page.current').parent().width() * 100;
      jQuery('.webform-progressbar-inner').css('width',newWidth + '%');
    }
    
    
    jQuery('form#webform-client-form-1652, form#webform-client-form-1652 input').keydown(function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        jQuery('button.button-primary').click();
      }
    });
    
  } // End Questionnaire Form Validation
  
  
  // Questionnaire Preview Page Tweaks
  if (jQuery('body.page-node-1652.webform-preview').length > 0) {
    var language = 'en';
    var q = 'Q: ';
    var a = 'A: ';
    
    if (window.location.pathname.indexOf("/es/") == 0) {
      language = 'es';
      q = 'P: ';
      a = 'R: ';
    }
    
    
    // Wrapping the Questions with a fieldset
    jQuery('.webform-component--question1, .webform-component--question-1-follow-up, .webform-component--question2, .webform-component--question-2-follow-up, .webform-component--question3, .webform-component--question4, .webform-component--question5, .webform-component--question6, .webform-component--question7, .webform-component--question8, .webform-component--question9, .webform-component--question10, .webform-component--question-10-follow-up, .webform-component--date-of-birth, .webform-component--question11, .webform-component--ltu-follow-up, .webform-page').wrapAll('<fieldset class="webform-component-fieldset webform-component--responses"><div class="fieldset-wrapper"></div></fieldset>');
    
    // Adding titles
    if (language == 'en') {
      jQuery('fieldset.webform-component--responses .fieldset-wrapper:first').before('<legend>Response Summary</legend>');
    }
    else {
      jQuery('fieldset.webform-component--responses .fieldset-wrapper:first').before('<legend>Resumen De Las Respuestas</legend>');
    }
    
    // Moved to template
    //jQuery('fieldset.webform-component--info .fieldset-wrapper').before('<legend>Personal Information</legend>');
    
    // Adding Q & A's to questions
    jQuery('.webform-component--question1 label, .webform-component--question2 label, .webform-component--question3 label, .webform-component--question4 label, .webform-component--question5 label, .webform-component--question6 label, .webform-component--question7 label, .webform-component--question8 label, .webform-component--question9 label, .webform-component--question10 label, .webform-component--question11 label, .webform-component--date-of-birth label').prepend(q);
    
    jQuery('.webform-component--question1 label, .webform-component--question2 label, .webform-component--question3 label, .webform-component--question4 label, .webform-component--question5 label, .webform-component--question6 label, .webform-component--question7 label, .webform-component--question8 label, .webform-component--question9 label, .webform-component--question10 label, .webform-component--question11 label, .webform-component--date-of-birth label').after(a);
    
    // Removing extra code
    jQuery('span.field-prefix, span.field-suffix').remove();
    
    // List of optional data fields that will be hidden if empty
    var selectArr = Array(
      '.webform-component--info--middle-initial',
      '.webform-component--info--location',
      '.webform-component--current-address--street-address-2',
      '.webform-component--current-address--phone-number',
      '.webform-component--current-address--county',
      '.webform-component--question-1-follow-up--question1-group--question1-fname',
      '.webform-component--question-1-follow-up--question1-group--question1-lname',
      '.webform-component--question-1-follow-up--question1-relationship',
      '.webform-component--question-1-follow-up--city-and-state-where-the-benefits-are-received--question1-city',
      '.webform-component--question-1-follow-up--city-and-state-where-the-benefits-are-received--question1-state',
      '.webform-component--question-2-follow-up--question2-group--question2-fname',
      '.webform-component--question-2-follow-up--question2-group--question2-lname',
      '.webform-component--question-2-follow-up--question2-relationship',
      '.webform-component--question-2-follow-up--city-and-state-where-the-benefits-are-received--question2-city',
      '.webform-component--question-2-follow-up--city-and-state-where-the-benefits-are-received--question2-state',
      '.webform-component--question-10-follow-up--name-of-parole-officer-if-known--parole-first-name',
      '.webform-component--question-10-follow-up--name-of-parole-officer-if-known--parole-last-name',
      '.webform-component--question-10-follow-up--city-and-state-of-conviction--conviction-city',
      '.webform-component--question-10-follow-up--city-and-state-of-conviction--conviction-state',
      '.webform-component--question-10-follow-up--conviction-date',
      '.webform-component--question-10-follow-up--release-date',
      '.webform-component--question-10-follow-up--probation-start-date',
      '.webform-component--question-10-follow-up--probation-end-date',
      '.webform-component--question-10-follow-up--adjudication-date'
    );
    var obj = jQuery(selectArr.join(", "));
    jQuery.each(obj, function() {
      var clone = jQuery(this).clone();
      clone.find('label').remove();
      if (jQuery.trim(clone.html()).length == 0) {
        jQuery(this).remove();
      }
    });
    
    // Removing the orphaned Legend elements
    var selectArr2 = Array(
      '.webform-component--question-1-follow-up--question1-group',
      '.webform-component--question-1-follow-up--city-and-state-where-the-benefits-are-received',
      '.webform-component--question-2-follow-up--question2-group',
      '.webform-component--question-2-follow-up--city-and-state-where-the-benefits-are-received',
      '.webform-component--question-10-follow-up--name-of-parole-officer-if-known',
      '.webform-component--question-10-follow-up--city-and-state-of-conviction'
    );
    
    for (var i = 0; i < selectArr2.length; i++) {
      if (jQuery(selectArr2[i] + ' .fieldset-wrapper div').length == 0) {
        jQuery(selectArr2[i]).remove();
      }
    }
    
    // Removing the orphaned fieldsets
    var selectArr3 = Array(
      '.webform-component--question-1-follow-up',
      '.webform-component--question-2-follow-up'
    );
    for (var i = 0; i < selectArr3.length; i++) {
      if (jQuery(selectArr3[i]).length > 0) {
        if (jQuery.trim(jQuery(selectArr3[i] + ' .fieldset-wrapper').html()).length == 0) {
          jQuery(selectArr3[i]).remove();
        }
      }
    }
    
    // Moving Webform Page Edit buttons next to Questions
    var qMap = {
      'fieldset.webform-component--info':'button.jump1',
      //'.webform-component--question-start-date':'button.jump2',
      '.webform-component--question1':'button.jump2',
      '.webform-component--question2':'button.jump3',
      '.webform-component--question3':'button.jump4',
      '.webform-component--question4':'button.jump5',
      '.webform-component--question5':'button.jump6',
      '.webform-component--question6':'button.jump7',
      '.webform-component--question9':'button.jump8',
      '.webform-component--question10':'button.jump9',
      '.webform-component--question11':'button.jump10',
      '.webform-component--date-of-birth':'button.jump11',
    };
    // Ask Drupal to translate the string in case we are in the spanish version
    var tstring_edit = Drupal.t('Edit');
    for(var key in qMap) {
      if (jQuery(key).length > 0) {
        jQuery(qMap[key]).html(tstring_edit);
        jQuery(key).prepend(jQuery(qMap[key]));
      } else {
        jQuery(qMap[key]).remove();
      }
      
    }
    
    
    
    // Match height of the Info and Address fieldsets
    if (jQuery('fieldset.webform-component--info').css('height') != jQuery('fieldset.webform-component--current-address').css('height')) {
      if (jQuery('fieldset.webform-component--info').css('height') > jQuery('fieldset.webform-component--current-address').css('height')) {
        jQuery('fieldset.webform-component--current-address').css('height',jQuery('fieldset.webform-component--info').outerHeight() + 'px');
      } else {
        jQuery('fieldset.webform-component--info').css('height',jQuery('fieldset.webform-component--current-address').outerHeight() + 'px');
      }
      
    }
    
    
  } // End Questionnaire Preview Page Tweaks
  
  if (jQuery('body.page-search-orgs').length > 0 || jQuery('body.page-search-contacts').length > 0) {
    jQuery('#edit-search-api-views-fulltext--2').focus();
  }
  
  // Node edit forms for employee
  if (jQuery('select#edit-field-client-und').length > 0 || jQuery('select#edit-field-employee-ref-und-form-field-client-und').length > 0) {
    if (jQuery('select#edit-field-client-und').length > 0) {
      var clientSelect = jQuery('select#edit-field-client-und');
    }
    else {
      var clientSelect = jQuery('select#edit-field-employee-ref-und-form-field-client-und');
    }
    updateLocations(clientSelect);
    clientSelect.on('change', function(){
      updateLocations(clientSelect);
    });
    
    updateClients(clientSelect);
    clientSelect.on('change', function(){
      updateClients(clientSelect);
    });
    
    
    
    jQuery('#edit-field-employee-ref-und-form-field-self-identified-und input[type="checkbox"]').each(function(index){
      jQuery(this).on('change', function(e){
        updateBoxes();
      });
    });
    
    jQuery('#edit-field-employee-ref-und-form-field-date-started-job-und-0-value-datepicker-popup-0').blur(function(e){
      if (jQuery('#edit-field-employee-ref-und-form-field-date-gave-information-und-0-value-datepicker-popup-0').val() == '' && jQuery('#edit-field-employee-ref-und-form-field-date-offered-job-und-0-value-datepicker-popup-0').val() == '' && jQuery('#edit-field-employee-ref-und-form-field-date-was-hired-und-0-value-datepicker-popup-0').val() == '') {
        jQuery('#edit-field-employee-ref-und-form-field-date-gave-information-und-0-value-datepicker-popup-0').val(jQuery('#edit-field-employee-ref-und-form-field-date-started-job-und-0-value-datepicker-popup-0').val());
        jQuery('#edit-field-employee-ref-und-form-field-date-offered-job-und-0-value-datepicker-popup-0').val(jQuery('#edit-field-employee-ref-und-form-field-date-started-job-und-0-value-datepicker-popup-0').val());
        jQuery('#edit-field-employee-ref-und-form-field-date-was-hired-und-0-value-datepicker-popup-0').val(jQuery('#edit-field-employee-ref-und-form-field-date-started-job-und-0-value-datepicker-popup-0').val());
      }
    });
    
    jQuery("#edit-field-employee-ref-und-form-field-ssn-und-0-value").mask("999-99-9999",{placeholder:" "});
    jQuery("#edit-field-employee-ref-und-form-field-phone-und-0-value").mask("999-999-9999",{placeholder:" "});
    jQuery("#edit-field-employee-ref-und-form-field-birthday-und-0-value-date").mask("99/99/9999",{placeholder:" "});
    
  }
  
  // Payroll Forms
  if (jQuery('body.page-node-payroll').length > 0) {
    jQuery('#edit-employee-ssn').mask("999-99-9999",{placeholder:" "});
  }
  
  // Exposed Filters
  if (jQuery('.views-exposed-form').length > 0 && jQuery('#edit-field-ssn-value').length > 0) {
    jQuery('#edit-field-ssn-value').mask("999-99-9999",{placeholder:" "});
  }
  
  // Checking for duplicate employee SSN entry on Client edit screen
  if (jQuery('#edit-field-certified-ssns').length > 0) {
    jQuery('#edit-field-certified-ssns .form-text').on('focusout', function(e){
      console.log(e.target.id);
      var $current = jQuery(this);
      jQuery('#edit-field-certified-ssns .form-text').each(function() {
        if (jQuery(this).val() == $current.val() && jQuery(this).attr('id') != $current.attr('id') && jQuery(this).val() != '') {
            alert('Duplicate found for: ' + jQuery(this).val());
        }
      });
    });
    
    jQuery('#edit-field-certified-ssns .field-add-more-submit').on('click', function(e){
      jQuery('#edit-field-certified-ssns .form-text').off('focusout');
    });
    
  }
  
  
  // Payroll entry screen
  if (jQuery('form#rja-payroll-add-payroll-form').length > 0) {
    jQuery('#rja-payroll-add-payroll-form #edit-date-fill').on('click', function(e){
      console.log("hey you clicked me!");
      jQuery('#rja-payroll-add-payroll-form .payroll-start-fill').each(function(e){
        jQuery(this).val(jQuery('#rja-payroll-add-payroll-form #edit-start-fill-datepicker-popup-0').val());
      });
      jQuery('#rja-payroll-add-payroll-form .payroll-end-fill').each(function(e){
        jQuery(this).val(jQuery('#rja-payroll-add-payroll-form #edit-end-fill-datepicker-popup-0').val());
      });
    });
    
    jQuery('.auto-fill-previous').on('click', function(e){
      console.log(jQuery(e.target).closest('tr').find('.previous_hours').html());
      jQuery(e.target).closest('tr').find('.hours').val(jQuery(e.target).closest('tr').find('.previous_hours').html());
      jQuery(e.target).closest('tr').find('.earnings').val(jQuery(e.target).closest('tr').find('.previous_ytd').html());
      
    });
      
    window.onbeforeunload = function(){
      return "Navigating away from this page may cause you to lose data.";
    }
    
  }
  
  jQuery('#get-invoice-summary').on('click', function(e){
    console.log(jQuery(this).val());
    jQuery('#invoice-summary-data').html('<p><img src="/sites/all/themes/rja/images/ajax-loader.gif" align="middle" /> Retrieving data...</p>');
    jQuery('#invoice-summary-data').load('/invoice-summary/get/ajax/' + jQuery(this).val(), function(e){
      console.log('done.');
    });
  });
  
  
}

function updateLocations(clientSelect) {
  if (jQuery('select#edit-field-location-und').length > 0) {
    var selectedOption = jQuery('select#edit-field-location-und').val();
    jQuery('select#edit-field-location-und').load('/location/get/ajax/' + clientSelect.val(), function(e){
      if (selectedOption != '_none') {
        jQuery('select#edit-field-location-und option[value=' + selectedOption +']').attr('selected', 'selected');
      }
    });
    
  }
  else {
    var selectedOption = jQuery('select#edit-field-employee-ref-und-form-field-location-und').val();
    jQuery('select#edit-field-employee-ref-und-form-field-location-und').load('/location/get/ajax/' + clientSelect.val(), function(e){
      if (selectedOption != '_none') {
        jQuery('select#edit-field-employee-ref-und-form-field-location-und option[value="' + selectedOption +'"]').attr('selected', 'selected');
      }
    });
    
  }
}

function updateClients(clientSelect) {
  if (jQuery('select#edit-field-moved-to-client-und').length > 0) {
    var selectedOption = jQuery('select#edit-field-moved-to-client-und').val();
    jQuery('select#edit-field-moved-to-client-und').load('/clients/get/ajax/' + clientSelect.val(), function(e){
      if (selectedOption != '_none') {
        jQuery('select#edit-field-moved-to-client-und option[value=' + selectedOption +']').attr('selected', 'selected');
      }
    });
    
  }
  else {
    var selectedOption = jQuery('select#edit-field-employee-ref-und-form-field-moved-to-client-und').val();
    jQuery('select#edit-field-employee-ref-und-form-field-moved-to-client-und').load('/clients/get/ajax/' + clientSelect.val(), function(e){
      if (selectedOption != '_none') {
        jQuery('select#edit-field-employee-ref-und-form-field-moved-to-client-und option[value="' + selectedOption +'"]').attr('selected', 'selected');
      }
    });
    
  }
}

function updateBoxes() {
  console.log('updateBoxes().');
  var checkedArr = new Array();
  jQuery('#edit-field-employee-ref-und-form-field-self-identified-und input[type="checkbox"]:checked').each(function(index){
    checkedArr.push(jQuery(this).val());
  });
  
  resetBoxes();
  // EZ
  if (jQuery.inArray('48', checkedArr) != -1) {
    //jQuery('#edit-field-8850-1-und').prop('checked', true);
    //jQuery('#edit-field-8850-1-und').attr('checked', true);
  }
  // Felon
  if (jQuery.inArray('44', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
  }
  // No
  if (jQuery.inArray('37', checkedArr) != -1) {}
  // SNAP
  if (jQuery.inArray('38', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
  }
  // SSI
  if (jQuery.inArray('42', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
  }
  // SYEZ
  if (jQuery.inArray('49', checkedArr) != -1) {
    //jQuery('#edit-field-8850-1-und').prop('checked', true);
    //jQuery('#edit-field-8850-1-und').attr('checked', true);
  }
  // TANF
  if (jQuery.inArray('41', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
    jQuery('#edit-field-8850-6-und').prop('checked', true);
    jQuery('#edit-field-8850-6-und').attr('checked', true);
  }
  // Veteran SNAP
  if (jQuery.inArray('39', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
  }
  // Veteran Unemployed Disabled
  if (jQuery.inArray('47', checkedArr) != -1) {
    jQuery('#edit-field-8850-5-und').prop('checked', true);
    jQuery('#edit-field-8850-5-und').attr('checked', true);
  }
  // Veteran Unemployed for 6 months
  if (jQuery.inArray('46', checkedArr) != -1) {
    jQuery('#edit-field-8850-3-und').prop('checked', true);
    jQuery('#edit-field-8850-3-und').attr('checked', true);
  }
  // Veteran Unemployed for less than 4 weeks
  if (jQuery.inArray('45', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
  }
  // Voc Rehab
  if (jQuery.inArray('43', checkedArr) != -1) {
    jQuery('#edit-field-8850-2-und').prop('checked', true);
    jQuery('#edit-field-8850-2-und').attr('checked', true);
  }
  // Veteran Disabled
  if (jQuery.inArray('65', checkedArr) != -1) {
    jQuery('#edit-field-8850-4-und').prop('checked', true);
    jQuery('#edit-field-8850-4-und').attr('checked', true);
  }
  // LTU
  if (jQuery.inArray('66', checkedArr) != -1) {
    jQuery('#edit-field-8850-7-und').prop('checked', true);
    jQuery('#edit-field-8850-7-und').attr('checked', true);
  }
  
}

function resetBoxes() {
  jQuery('#edit-field-8850-1-und').prop('checked', false);
  jQuery('#edit-field-8850-1-und').attr('checked', false);
  jQuery('#edit-field-8850-2-und').prop('checked', false);
  jQuery('#edit-field-8850-2-und').attr('checked', false);
  jQuery('#edit-field-8850-3-und').prop('checked', false);
  jQuery('#edit-field-8850-3-und').attr('checked', false);
  jQuery('#edit-field-8850-4-und').prop('checked', false);
  jQuery('#edit-field-8850-4-und').attr('checked', false);
  jQuery('#edit-field-8850-5-und').prop('checked', false);
  jQuery('#edit-field-8850-5-und').attr('checked', false);
  jQuery('#edit-field-8850-6-und').prop('checked', false);
  jQuery('#edit-field-8850-6-und').attr('checked', false);
  jQuery('#edit-field-8850-7-und').prop('checked', false);
  jQuery('#edit-field-8850-7-und').attr('checked', false);
}


function Init_Tables() {

	jQuery('.views-table th').each(function(e) {

    jQuery(this).addClass( "modified" );

    jQuery(this).wrapInner( "<span class='wrapper'></span>" );

    var SortableFields = jQuery(this).find('a');

    if (SortableFields.length) {

      SortableFields.parent().parent().addClass('sortable');

      var ClassToAdd = jQuery(this).find('img').attr('alt');

      jQuery(this).addClass(ClassToAdd);

    } 

	});

}


function Init_State_Dropdown() {
  if (jQuery('select.state.form-select').length > 0) {
    jQuery('select.state.form-select > option').each(function() {
      if (jQuery(this).val() != '' && jQuery(this).val() != ' ') {
        jQuery(this).text(jQuery(this).val());
      }
    });
    
  }
}