/*
//
/// Basic functionality for a mobile menu */

(function (jQuery, window, document) {

  /*
  //
  /// Functions */
  
  
  // Bind the mobile menu button to open / close the mobile menu
  function Bind_Mobile_Menu_Button(){
    
    
        // Locally cache the menu block element
    var menu_block = jQuery('.main-menu'),
    
    
        // The class that will be toggled
        menu_closed_class = 'menu-closed',
        
        
        // The speed at which the menu will open/close
        menu_transition_speed = 500;
    
    
    // Set the menu state to closed
    menu_block.addClass(menu_closed_class);
    
    
    // Bind the event
    jQuery('.toggle-topbar').on("click", function(e){
      console.log('click');
      
      
      // If the menu is closed
      if(menu_block.hasClass(menu_closed_class)){
        
        
        // Set state to open
        menu_block.removeClass(menu_closed_class);
        
        
        // Slide open the menu
        menu_block.slideDown(menu_transition_speed);
      }
      
      
      // If the menu is opened
      else{
       
       
        // Set state to closed
        menu_block.addClass(menu_closed_class);
        
        
        // Slide closed the menu
        menu_block.slideUp(menu_transition_speed);        
      }      
    });
  }
  
  
  /*
  //
  /// Run */
  
  jQuery(document).ready(function(e){
    
    
    // Bind the mobile menu button to open / close the mobile menu
    Bind_Mobile_Menu_Button();
    
  });  
})(jQuery, window, document);